import axios from "axios";

export default {
    namespaced: true,
    state: {
        user: [],
        classes: [],
    },
    getters: {
        getUser: state => state.user,
        getUserClasses: state => state.classes,
    },
    mutations: {
        setUser: (state, user) => {
            state.user = user
        },
        setUserClasses: (state, classes) => {
            state.classes = classes
        },
    },
    actions: {
        fetchUser(ctx, data) {
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
            return new Promise((resolve, reject) => {
                axios
                    .get(`user/${data.id}`)
                    .then(response => {
                        ctx.commit('setUser', response.data.data)
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        updateUser(ctx, data) {
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
            return new Promise((resolve, reject) => {
                axios
                    .put(`user/${data.id}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        updateAvatarUser(ctx, data) {
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
            return new Promise((resolve, reject) => {
                axios
                    .post(`user-avatar`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        fetchUserClasses(ctx) {
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
            return new Promise((resolve, reject) => {
                axios
                    .get(`class`)
                    .then(response => {
                        ctx.commit('setUserClasses', response.data.data)
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        fetchUserSelectedClass(ctx, data) {
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
            return new Promise((resolve, reject) => {
                axios
                    .get(`class/${data.id}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        updateUserUsageTimestamp(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`user-access`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        logoutUser(ctx) {
            ctx.commit('setUser', [])
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
            return new Promise((resolve, reject) => {
                axios
                    .post(`logout`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
    },
}
