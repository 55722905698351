import {createRouter, createWebHistory,} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '',
        redirect: 'login',
    },
    {
        path: '/home',
        component: () => import ('../views/home/Home.vue')
    },
    {
        name: 'login',
        path: '/login',
        component: () => import ('../views/auth/Login.vue')
    },
    {
        name: 'selectclass',
        path: '/selectclass',
        component: () => import ('../views/select-class/SelectClass.vue'),
    },
    {
        name: 'newapp',
        path: '/newapp',
        component: () => import ('../views/newapp/NewApp.vue')
    },
    {
        name: 'downloadapp',
        path: '/downloadapp',
        component: () => import ('../views/downloadapp/DownloadApp.vue')
    },
    {
        name: 'logout',
        path: '/logout',
        component: () => import ('../views/auth/Logout.vue')
    },
    {
        path: '/folder/:id',
        component: () => import ('../views/Folder.vue')
    },
    {
        name: 'play',
        path: '/play',
        component: () => import ('../views/play/Play.vue')

    },
    {
        name: 'quiz',
        path: '/quiz/:id',
        component: () => import ('../views/play/Quiz.vue')
    },
    {
        path: '/quiz/congratulations',
        component: () => import ('../views/play/Congratulations.vue')
    },
    {
        path: '/training',
        // component: () => import ('../views/training/Training.vue')
        component: () => import ('../views/file/File.vue'),
        meta: {
            type: 'training'
        }
    },
    {
        path: '/pressroom',
        // component: () => import ('../views/pressroom/Pressroom.vue')
        component: () => import ('../views/file/File.vue'),
        meta: {
            type: 'pressroom'
        }
    },
    {
        path: '/ranking',
        component: () => import ('../views/ranking/Ranking.vue')
    },
    {
        path: '/options',
        component: () => import ('../views/options/Options.vue')
    },
    {
        path: '/rules',
        component: () => import ('../views/rules/Rules.vue')
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
