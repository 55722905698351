
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        matches: [],
        currentMatch: [],
        matchResult: [],
    },
    getters: {
        getListMatches: state => state.matches,
        getCurrentMatch: state => state.currentMatch,
        getMatchResult: state => state.matchResult,
    },
    mutations: {
        setListMatches: (state, matches) => {
            state.matches = matches
        },
        setCurrentMatch: (state, match) => {
            state.currentMatch = match
        },
        setMatchResult: (state, result) => {
            state.matchResult = result
        },
    },
    actions: {
        fetchMatches(ctx, queryParams) {
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
            return new Promise((resolve, reject) => {
                axios
                    .get('match', { params: queryParams })
                    .then(response => {
                        ctx.commit('setListMatches', response.data.data.matches)
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },

        fetchMatch(ctx, queryParams) {
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
            return new Promise((resolve, reject) => {
                axios
                    .get(`match/${queryParams.match_id}`, { params: queryParams })
                    .then(response => {
                        ctx.commit('setCurrentMatch', response.data.data)
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },

        updateMatch(ctx, data) {
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
            return new Promise((resolve, reject) => {
                axios
                    .put(`match/${data.id}`, data)
                    .then(response => {
                        ctx.commit('setMatchResult', response.data.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
