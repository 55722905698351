
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        ranking: []
    },
    getters: {
        getRanking: state => state.ranking,
    },
    mutations: {
        setRanking: (state, ranking) => {
            state.ranking = ranking
        },
    },
    actions: {
        fetchRanking(ctx, queryParams) {
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
            return new Promise((resolve, reject) => {
                axios
                    .get('ranking', { params: queryParams })
                    .then(response => {
                        ctx.commit('setRanking', response.data)
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
    },
}
