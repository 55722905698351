
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        trainings: [],
    },
    getters: {
        getListTrainings: state => state.trainings,
    },
    mutations: {
        setListTrainings: (state, trainings) => {
            state.trainings = trainings
        },
    },
    actions: {
        fetchTrainings(ctx, queryParams) {
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
            return new Promise((resolve, reject) => {
                axios
                    .get('training', { params: queryParams })
                    .then(response => {
                        ctx.commit('setListTrainings', response.data.data.training)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
    },
}
