import {ref, onMounted} from 'vue';
import store from '@/store'
import {Camera, CameraResultType, CameraSource} from '@capacitor/camera';
import {toastController} from "@ionic/vue";
import axios from "axios";

//Creo un interface per il salvataggio della foto della fotocamera o il caricamento da galleria
export interface UserPhoto {
    filepath: string;
    webviewPath?: string;
}

export default function () {

    async function openToastOptions(data, color = 'success') {
        const toast = await toastController.create({
            color: color,
            duration: 2000,
            message: data,
        });
        await toast.present();
    }

    const data = ref<any>(null)

    const colorOptions = [
        {
            'name': 'Medium Turquoise',
            'hex': '#48D1CC',
        },
        {
            'name': 'Light Steel Blue',
            'hex': '#B0C4DE',
        },
        {
            'name': 'Amethyst',
            'hex': '#9966CC',
        },
        {
            'name': 'Orchid',
            'hex': '#DA70D6',
        },
        {
            'name': 'Dark Magenta',
            'hex': '#8B008B',
        },
        {
            'name': 'Royal Blue',
            'hex': '#4169E1',
        },
        {
            'name': 'Yellow Green',
            'hex': '#9ACD32',
        },
        {
            'name': 'Green Yellow',
            'hex': '#ADFF2F',
        },
        {
            'name': 'Brown',
            'hex': '#A52A2A',
        },
        {
            'name': 'Dark Green',
            'hex': '#006400',
        },
        {
            'name': 'Chocolate',
            'hex': '#D2691E',
        },
        {
            'name': 'Dark Red',
            'hex': '#8B0000',
        },
        {
            'name': 'Fire Brick',
            'hex': '#B22222',
        },
        {
            'name': 'Dark Khaki',
            'hex': '#BDB76B',
        },
        {
            'name': 'Plum',
            'hex': '#DDA0DD',
        },
    ]

    const fetchUser = () => {
        store
            .dispatch('user/fetchUser', JSON.parse(localStorage.getItem('userData') || '{}'))
            .then(response => {
                delete response.data.data.avatars
                localStorage.setItem('userData', JSON.stringify(response.data.data))
            })
            .catch(error => {
                console.log(error.response)
            })
    }

    const fetchUserClasses = () => {
        store
            .dispatch('user/fetchUserClasses')
            .catch(error => {
                console.log(error.response)
            })
    }

    const fetchUserSelectedClass = data => {
        return store.dispatch('user/fetchUserSelectedClass', data)
            .then(response => response)
            .catch(error => {
                console.log(error.response)
            })
    }

    const updateUserUsageTimestamp = data => {
        return store.dispatch('user/updateUserUsageTimestamp', data)
            .catch(error => {
                console.log(error.response)
            })
    }

    // onMounted(() => {
    //     // fetch('https://jsonplaceholder.typicode.com/todos/1')
    //     //     .then((response) => response.json())
    //     //     .then((json) => (data.value = json))
    //     data.value = JSON.parse(localStorage.getItem('userData') || '{}')
    // })

    const getUser = () => store.getters['user/getUser']
    const getUserClasses = () => store.getters['user/getUserClasses']


    const resolveUserColor = () => {
        return colorOptions.find(color => {
            return color.hex === getUser().color
        })
    }

    const takePhoto = async () => {
        const photo = await Camera.getPhoto({
            resultType: CameraResultType.Uri,
            // resultType: CameraResultType.Base64,
            source: CameraSource.Camera,
            quality: 50,
        });

        if (photo) {
            const fileName = new Date().getTime() + '.jpeg';
            const formData = new FormData()
            const response = await fetch(photo.webPath!)
            const blob = await response.blob()
            formData.append('avatar', blob, fileName)
            store.dispatch('user/updateAvatarUser', formData)
                .then(() => {
                    openToastOptions('Avatar aggiornato', 'success')
                })
                .catch(() => {
                    openToastOptions('Impossibile aggiornare il tuo avatar', 'danger')
                })
        }
    }

    function resolveUserAvatar(user) {
        if (user && user.avatar) {
            return {
                avatar: user.avatar,
                alt: user.first_name,
            }
        } else {
            return {
                avatar: require('@/assets/avatar/avatar.svg'),
                alt: user.first_name,
            }
        }
    }

    return {
        data,
        colorOptions,
        fetchUser,
        fetchUserClasses,
        fetchUserSelectedClass,
        updateUserUsageTimestamp,

        getUser,
        getUserClasses,
        resolveUserColor,
        takePhoto,
        resolveUserAvatar,
    }
}
