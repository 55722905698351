import Vuex from 'vuex'

//Modules
import trainingStoreModule from '@/store/training/trainingStoreModule'
import pressroomStoreModule from '@/store/pressroom/pressroomStoreModule'
import matchesStoreModule from '@/store/matches/matchesStoreModule'
import rankingStoreModule from '@/store/ranking/rankingStoreModule'
import userStoreModule from '@/store/user/userStoreModule'
import fileStoreModule from '@/store/file/fileStoreModule'

export default new Vuex.Store({
  modules: {
    training: trainingStoreModule,
    pressroom: pressroomStoreModule,
    match: matchesStoreModule,
    ranking: rankingStoreModule,
    user: userStoreModule,
    file: fileStoreModule
  }
})
