
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        files: [],
    },
    getters: {
        getListFiles: state => state.files,
    },
    mutations: {
        setListFiles: (state, files) => {
            state.files = files
        },
    },
    actions: {
        fetchFiles(ctx, queryParams) {
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
            return new Promise((resolve, reject) => {
                axios
                    .get('file', {params: queryParams})
                    .then(response => {
                        ctx.commit('setListFiles', response.data.data.files)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        updateFileUsageTimestamp(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`file-access`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
    }
}
