import {createApp} from 'vue'
import App from './App.vue'
import router from './router';
import axios from "axios";
import "@/app/app.scss";

//Setto i parametri di default per axios
axios.interceptors.response.use(response => response, error => {
    console.log(error.response)
    if (error.response.status === 401 || error.response.status === 403) {
        console.log('logout')
        // Remove userData from localStorage
        localStorage.removeItem('userData')
        localStorage.removeItem('accessToken')

        // Reset ability
        // ability.update(initialAbility)

        // Redirect to login page
        router.replace({path: '/'})
    }
    return Promise.reject(error)
})
axios.defaults.baseURL = `${process.env.VUE_APP_APIURL}`;
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');

import {IonicVue} from '@ionic/vue';

router.beforeEach((to, from, next) => {
    //Per prenire il torna indietro fino a login su android controllo che per entrare nella pagina login non ci sia il token nel local storage
    if (to.path == '/login' && localStorage.getItem('accessToken')) {
        next({path: '/home'})
    } else {
        next()
    }
})

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import 'swiper/swiper.min.css';
import '@ionic/vue/css/ionic-swiper.css';

//Custom Css
import './theme/custom.css';

//2e1587e762be4dac937af8f6a9a52a81
import store from './store'

const app = createApp(App)
    .use(store)
    .use(IonicVue)
    .use(router);

router.isReady().then(() => {
    app.mount('#app');
});
