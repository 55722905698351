import {ref} from "vue"
import store from '@/store'
import {toastController} from '@ionic/vue';
import axios from "axios";
import {
    caretForwardCircleOutline,
    documentOutline,
    imagesOutline,
    closeCircleOutline
} from 'ionicons/icons';

const data = ref<any>(null)

export default function FileService() {

    const argumentFileType = ref<any>('')
    const perPage = ref(25)
    const totalUsers = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('codice')
    const isSortDirDesc = ref(true)

    async function openToastOptions(data, color = 'success') {
        const toast = await toastController.create({
            color: color,
            duration: 2000,
            message: data,
        });
        await toast.present();
    }

    const fetchPDF = url => {
        const baseURL = axios.defaults.baseURL
        const authorization = axios.defaults.headers.common['Authorization']
        axios.defaults.baseURL = ''
        delete axios.defaults.headers.common['Authorization']
        return new Promise((resolve, reject) => {
            axios({
                url: url,
                method: 'GET',
                responseType: 'blob',
            })
                .then(response => {
                    resolve(response)
                })
                .catch(error => reject(error))

            axios.defaults.baseURL = baseURL
            axios.defaults.headers.common['Authorization'] = authorization;
        })
    }

    const fetchFile = () => {
        console.log(argumentFileType.value)
        if (argumentFileType.value) {
            store
                .dispatch('file/fetchFiles', {
                    'class_id': JSON.parse(localStorage.getItem('currentClass') || '{}').id,
                    'file_type': argumentFileType.value
                })
                .catch(error => {
                    console.log(error.response)
                    if (error.response.status != 422) {
                        openToastOptions('Impossibile caricare i files', 'danger')
                    }
                })
        }
    }

    const updateFileUsageTimeStamp = () => {
        const currentClass = localStorage.getItem('currentClass')
        const currentFile = localStorage.getItem('currentFile')
        const fileOpenedAt = localStorage.getItem('fileOpenedAt')
        if (currentClass && currentFile && fileOpenedAt) {
            store
                .dispatch('file/updateFileUsageTimestamp',
                    {
                        'class_id': JSON.parse(currentClass).id,
                        'file_id': JSON.parse(currentFile).id,
                        'time': Number(((Date.now() / 1000) - Number(JSON.parse(fileOpenedAt))).toFixed(0))
                    })
                .catch(error => {
                    console.log(error.response)
                    if (error.response.status != 422) {
                        openToastOptions('Impossibile caricare i files', 'danger')
                    }
                })
        }
    }


    const getCurrentFile = () => store.getters['file/getListFiles']

    const handleFileIcon = type => {
        let res = {}
        if (type === 'file') {
            res = {
                icon: documentOutline,
                text: 'PDF'
            }
        } else if (type === 'image') {
            res = {
                icon: imagesOutline,
                text: 'Image'
            }
        } else if (type === 'video') {
            res = {
                icon: caretForwardCircleOutline,
                text: 'Video'
            }
        } else {
            res = {
                icon: closeCircleOutline,
                text: 'Error'
            }
        }
        return res
    }

    return {
        data,
        fetchPDF,
        fetchFile,
        updateFileUsageTimeStamp,

        getCurrentFile,

        handleFileIcon,

        argumentFileType,
        perPage,
        currentPage,
        totalUsers,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
    }
}
