
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        pressrooms: [],
    },
    getters: {
        getListPressrooms: state => state.pressrooms,
    },
    mutations: {
        setListPressrooms: (state, pressrooms) => {
            state.pressrooms = pressrooms
        },
    },
    actions: {
        fetchPressrooms(ctx, queryParams) {
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
            return new Promise((resolve, reject) => {
                axios
                    .get('pressroom', { params: queryParams })
                    .then(response => {
                        ctx.commit('setListPressrooms', response.data.data.pressroom)
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
    },
}
